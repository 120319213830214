import React from "react"

import Layout from "../components/layout"

const HomePage = () => {
  return (
    <Layout title="Bao Ho">
      <h1 className="text-3xl font-semibold">Bao Ho</h1>
      <h2 className="mt-10 text-green-300">
        A software developer who solves problems and ships user-centric
        products. Worked at startups and enterprises. Experience in JavaScript,
        TypeScript, React, Node, etc. A passionate individual contributor and
        team player.
      </h2>
    </Layout>
  )
}

export default HomePage
